<template>
  <el-dialog
    :title="title"
    :visible="evaluateRecordTableDialog"
    width="70%"
    class="editDialogs"
    :close-on-click-modal="false"
    @close="dialogClose"
    center
    append-to-body
  >
    <div class="bar" style="text-align: left; margin-top: 20px">
      <li>
        <b>项目/投资机构：</b>
        <span>{{ evaluateRecordIfno.projectName }}</span>
      </li>
      <li>
        <b>流程：</b>
        <span>{{ evaluateRecordIfno.projectWorkflowName }}</span>
      </li>
      <li>
        <b>模版名称：</b>
        <span>{{ evaluateRecordIfno.templateName }}</span>
      </li>
      <li>
        <b>评估时点：</b>
        <span>{{ moduleScoreHeaderName }}</span>
      </li>
      
      <li>
        <b>评估人：</b>
        <span>{{ evaluateRecordIfno.evaluatorName }}</span>
      </li>
      <li>
        <b>记录人：</b>
        <span>{{ evaluateRecordIfno.recorderName }}</span>
      </li>
      <li>
        <b>时间：</b>
        <span>{{ evaluateRecordIfno.beginTime | formatDateFilter }}</span>
      </li>
    </div>
    <el-table
      v-loading="tableLoading"
      ref="multipleTable"
      width="100%"
      border
      :span-method="arraySpanMethod"
      :data="evaluateRecordTableData"
      style="width: 100%; margin-top: 10px"
    >
      <el-table-column label="模块" align="center" fixed="left">
        <template slot-scope="scope" width="160">
          {{ scope.row.moduleName }}
          <br />
          <span class="explain">
            {{ scope.row.moduleExplain }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="细分模块" align="center" fixed="left">
        <template slot-scope="scope" width="160">
          {{ scope.row.subModuleName }}
          <br />
          <span class="explain">
            {{ scope.row.subModuleExplain }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="评估要素" width="180" align="center" fixed="left">
        <template slot-scope="scope">
          <p>{{ scope.row.question }}</p>
        </template>
      </el-table-column>
      <el-table-column label="评估得分" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.score }}</p>
        </template>
      </el-table-column>
      <el-table-column label="模块评估得分" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.moduleScore.split('-')[0] }}</p>
        </template>
      </el-table-column>
    </el-table>
    <div class="bar" style="text-align: left; margin-top: 20px">
      <li class="width100">
        <b>总分：</b>
        <span>{{ evaluateRecordIfno.totalScore }}</span>
      </li>
       <li class="width100">
        <b>评估评价：</b>
        <span>{{ evaluateRecordIfno.comment }}</span>
      </li>
      
    </div>

      <!-- 评论 -->
    <el-divider content-position="center">评论</el-divider>
    <div class="comment" v-if="id">
      <comment :subjectType="subjectType" :subjectId="id"></comment>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogClose">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formatDate } from "@/common/date";
import {
  projecTrackAllDetail,
  evaluationRecord,
  getEvaluationRecordDetail,
} from "@/api/projectManage";
import comment from "@/components/comment/comment.vue";

export default {
  name: "evaluateRecordTable",
  props: {
    type: String,
    evaluateRecordTableDialog: Boolean,
    id: String,
  },
  data() {
    return {
      detail: {},
      activeName: "first",
      loading: false,
      title: null,
      contentDialog: false,
      contentDialogTitle: null,
      content: null,
      evaluateRecordTableData: [],
      needMergeArr: ["moduleName", "subModuleName", "moduleScore"], // 有合并项的列
      rowMergeArrs: {}, // 包含需要一个或多个合并项信息的对象
      textArr: [],
      spanArr: [],
      tableLoading: false,
      indexOf: [0],
      // pos:0,
      chnNumChar: ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"],
      chnUnitSection: ["", "万", "亿", "万亿", "亿亿"],
      chnUnitChar: ["", "十", "百", "千"],

      evaluationTimes: 0,
      contentDialogType: null,
      evaluateRecordIfno: {
        projectName: null,
      },
      scoreHeaderName: null,
      moduleScoreHeaderName: null,
      add1TaskDalog: false,
      add1TaskType: null,
      relationList: null,
      dialogVisible: false,
      pdfUrl: null,
      imgUrl: null,
      scale: 100,
      subjectType: "EVALUATION_DETAIL",

    };
  },
  components: {
    comment
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      if (time) return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    businessStatus(value) {
      switch (value) {
        case 0:
          return "未知";
          break;
        case 1:
          return "待启动";
          break;
        case 2:
          return "进行中";
          break;
        case 3:
          return "已完成";
          break;
        case 4:
          return "终止";
          break;
        case 5:
          return "暂停";
          break;
      }
    },
  },
  computed: {},
  watch: {
    id(val, i) {
      if (val) {
        // this.getEvaluationRecordDetail({ id: this.id });
      }
    },
    deep: true,
  },
  mounted() {
    this.getEvaluationRecordDetail({ id: this.id });
  },
  methods: {
    getEvaluationRecordDetail(info) {
      console.log("data1");
      var that = this;
      that.tableLoading = true;
      that.evaluateRecordTableData = [];

      getEvaluationRecordDetail(info).then((res) => {
        if (res.success) {
          let info = res.data;
          that.evaluateRecordIfno = info;
          that.evaluateRecordTableData = info.recordItemList;
          that.evaluateRecordTableData.forEach((item) => {
            item.moduleScore = item.moduleScore + "-" + String(item.moduleName);
          });

          this.moduleScoreHeaderName =
            "V" +
            info.workflowVersion +
            info.projectWorkflowName +
            "_" +
            info.workflowNodeName +
            "_" +
            info.taskName;
          that.rowMergeArrs = that.rowMergeHandle(
            that.needMergeArr,
            that.evaluateRecordTableData
          ); // 处理数据
          that.tableLoading = false;
          that.contentDialog = true;
        }
      });
    },

    /**
     * @description 实现合并行或列
     * @param row:Object 需要合并的列name 如:'name' 'id'
     * @param column:Object 当前行的行数，由合并函数传入
     * @param rowIndex:Number 当前列的数据，由合并函数传入
     * @param columnIndex:Number 当前列的数据，由合并函数传入
     *
     * @return 函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，第二个元素代表colspan。 也可以返回一个键名为rowspan和colspan的对象
     * 参考地址：https://element.eleme.cn/#/zh-CN/component/table#table-biao-ge
     */
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(row);
      console.log(column);
      // 没办法循环判断具体是那一列 所以就只好写了多个if
      if (column.label === "模块") {
        return this.mergeAction("moduleName", rowIndex, column);
      }
      if (column.label === "细分模块") {
        return this.mergeAction("subModuleName", rowIndex, column);
      }
      if (column.label === "模块评估得分") {
        return this.mergeAction("moduleScore", rowIndex, column);
      }
    },
    /**
     * @description 根据数组来确定单元格是否需要合并
     * @param val:String 需要合并的列name 如:'name' 'id'
     * @param rowIndex:Number 当前行的行数，由合并函数传入
     * @param colData:Object 当前列的数据，由合并函数传入
     *
     * @return 返回值为一个数组表示该单元格是否需要合并; 说明: [0,0]表示改行被合并了 [n+,1]n为1时表示该单元格不动,n大于1时表示合并了N-1个单元格
     */
    mergeAction(val, rowIndex, colData) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },
    /**
     * @description 根据数组将指定对象转化为相应的数组
     * @param arr:Array[String] 必填 必须是字符串形式的数组
     * @param data:Array 必填 需要转化的对象
     *
     * @return 返回一个对象
     * 例：rowMerge(['name','value'], [{value:'1', name:'小明'}, {value:'2', name:'小明'}, {value:'3', name:'小明'}, {value:'1', name:'小明'}, {value:'1', name:'小明'}])
     * 返回值: {
     *          name:{
     *            rowArr: [5, 0, 0, 0, 0]
     *            rowMergeNum: 0,
     *          },
     *          value: {
     *            rowArr: [1, 1, 1, 2, 0],
     *            rowMergeNum: 3
     *          }
     *        }
     */
    rowMergeHandle(arr, data) {
      if (!Array.isArray(arr) && !arr.length) return false;
      if (!Array.isArray(data) && !data.length) return false;
      let needMerge = {};
      arr.forEach((i) => {
        console.log(i);
        needMerge[i] = {
          rowArr: [],
          rowMergeNum: 0,
        };
        data.forEach((item, index) => {
          if (index === 0) {
            needMerge[i].rowArr.push(1);
            needMerge[i].rowMergeNum = 0;
          } else {
            if (item[i] === data[index - 1][i]) {
              needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1;
              needMerge[i].rowArr.push(0);
            } else {
              needMerge[i].rowArr.push(1);
              needMerge[i].rowMergeNum = index;
            }
          }
        });
      });
      return needMerge;
    },
    // 2、 因为要合并的行数是不固定的，此函数是实现合并随意行数的功能
    getSpanArr(data) {
      console.log("data");
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].moduleName === data[i - 1].moduleName) {
            // 如果moduleName相等就累加，并且push 0  这里是根据一样的moduleName匹配
            console.log(data[i].moduleName);
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
      this.contentDialog = true;
      this.tableLoading = false;

      this.$forceUpdate();
    },
    // 3、合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      for (let i = 0; i < this.indexOf.length; i++) {
        if (columnIndex === this.indexOf[i]) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },
    dialogClose() {
      this.$emit("listenEvaluateRecordTableClose", {
        type: "cancel",
        isClose: true,
      });
    },
  },
  created() {},

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.el-table {
  color: #000;
  .explain {
    color: #606266;
    font-size: 12px;
  }
}
.version {
  color: #606266;
  font-size: 12px;
  margin-left: 5px;
  // align-self: flex-end;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 120px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 100px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
    span {
      width: calc(100% - 100px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
  }
}
</style>