<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-03-16 14:33:09
 * @LastEditTime: 2022-08-02 11:30:17
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/views/officeOnLine.vue
-->
<template>
  <div class="office">
    <div class="officeOnLine" v-if="!previewDialogVisible">
      <iframe
        ref="officeOnLine"
        :src="url"
        frameborder="0"
        style="width: 100%; height: 80vh"
      ></iframe>
    </div>
    <div class="imgUrl flexJA" v-if="previewDialogVisible">
      <el-image
        style="width: 300px; height: auto"
        :src="previewImgUrl"
        :preview-src-list="previewImgUrlList"
      >
      </el-image>
    </div>
    <!-- 文件预览 -->
    <!-- <el-dialog
      title="文件预览"
      center
      :visible.sync="previewDialogVisible"
      @close="closePreviewDialog"
      :close-on-click-modal="false"
      style="margin-top: -5vh"
      width="70%"
    >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePreviewDialog">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  name: "officeOnLine",
  components: {},
  data() {
    return {
      url: null,
      previewDialogVisible: false,
      previewImgUrl: null,
      previewImgUrlList: [],
    };
  },
  mounted() {},
  activated() {
    if (this.$route.params) {
      //   this.url = JSON.parse(JSON.stringify(this.$route.params.url));
      this.handleExport(
        this.$route.params.file,
        this.$route.params.url,
        this.$route.params.fileType
      );
    }
  },
  methods: {
    handleExport(file, downloadUri, fileType) {
      //   let fileType = file.fileName.split(".")[1] || file.name.split(".")[1];
      console.log(file);
      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        // let url = encodeURIComponent(downloadUri);
        // window.open(
        //   "https://view.officeapps.live.com/op/view.aspx?src=" +
        //     encodeURI(downloadUri)
        // );
        this.previewDialogVisible = false;
        this.url =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          encodeURI(downloadUri);
      } else if (fileType == "pdf") {
        this.previewDialogVisible = false;
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + downloadUri;
        this.url = origin + "/pdf/web/viewer.html?file=" + downloadUri;
        // window.open(encodeURI(url));
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif" ||
        fileType == "JPG" ||
        fileType == "JPEG" ||
        fileType == "PNG" ||
        fileType == "GIF"
      ) {
        this.previewImgUrl = downloadUri;
        this.previewImgUrlList.push(downloadUri);
        this.previewDialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(encodeURI(downloadUri));
      }
    },
    closePreviewDialog() {
      this.previewImgUrl = null;
      this.previewDialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.office {
  height: 100%;
  .officeOnLine {
    //   width: 100%;
    //   height: 100%;
  }
  .imgUrl {
    width: 100%;
    height: 100%;
  }
}
</style>
