<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="add1TaskDalog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form
      :model="formData"
      ref="editTask1"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="" class="roleCodeList" prop="">
        <el-switch
          style="display: inline-block; text-align: right;"
          v-model="templateIdShow"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="使用任务模版"
          inactive-text="不用任务模版"
          @change="changeTemplatShow($event)"
          :disabled="this.type === 'detail' || this.type === 'edit'"
        >
        </el-switch>
      </el-form-item>

      <el-form-item v-show="workflowType === 'workflow'" label="业务进度表" prop="projectWorkflowId">
        <el-select
          v-model="formData.projectWorkflowId"
          clearable
          filterable
          placeholder="请选择业务进度表"
          @visible-change="findMyProjectWorkflowList($event)"
          @change="onMyProjectWorkflowListChange"
          :disabled="this.type !== 'add'"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in myProjectWorkflowList"
            :key="item.id"
            :label="item.projectName + ' - ' + item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-show="workflowType === 'workflow'" label="流程节点" prop="projectWorkflowDetailId">
        <el-select
          v-model="formData.projectWorkflowDetailId"
          clearable
          filterable
          placeholder="请选择流程节点"
          @visible-change="selectListWorkflow($event)"
          :disabled="this.type !== 'add'"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in myProjectWorkflowDetailList"
            :key="item.id"
            :label="item.nodeName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="任务模版" v-show="templateIdShow" prop="templateId">
        <el-select
          v-model="formData.templateId"
          class="childWidth"
          filterable
          placeholder="请选择任务模版"
          @visible-change="selectListTaskTemplate($event)"
          @change="choosePorject($event)"
          clearable
          :disabled="this.type === 'detail'"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in taskTemplateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="!templateIdShow" label="任务名称" prop="name">
        <el-input
          type="text"
          placeholder="请填写任务名称"
          show-word-limit
          v-model="formData.name"
          clearable
          :disabled="this.type === 'detail'"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-show="!templateIdShow"
        label="任务描述"
        prop="description"
      >
        <el-input
          type="text"
          placeholder="请填写任务描述"
          show-word-limit
          v-model="formData.description"
          clearable
          :disabled="this.type === 'detail'"
        ></el-input>
      </el-form-item>
      <el-form-item label="任务负责人" prop="ownerId">
        <el-select
          v-model="formData.ownerId"
          @visible-change="findMyProjectWorkflowMembers($event)"
          filterable
          placeholder="请选择"
          clearable
          :disabled="this.type === 'detail'"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in myProjectWorkflowMembers"
            :key="item.uid"
            :label="item.real_name"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="操作" prop="operationId">
        <el-select
          v-model="formData.operationId"
          filterable
          placeholder="请选择"
          clearable
          :disabled="this.type === 'detail' || this.type === 'edit'"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in operationList"
            :key="item.id"
            :label="item.lable"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="计划开始时间" prop="planBeginTime">
        <el-date-picker
          v-model="formData.planBeginTime"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          style="width: 100% !important"
          value-format="timestamp"
          :disabled="this.type === 'detail'"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="计划结束时间" prop="planEndTime">
        <el-date-picker
          v-model="formData.planEndTime"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          style="width: 100% !important"
          value-format="timestamp"
          :disabled="this.type === 'detail'"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="启用" prop="rating">
        <el-switch
          v-model="formData.enabled"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </el-form-item>     -->
    </el-form>

    <div class="dialog-footer" v-if="type === 'add' || type === 'edit'">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {selectListTaskTemplate} from "@/api/taskTemplate";
import {addTask1, editTask1, findMyProjectWorkflowMembers} from "@/api/myMission";
import {projectTeam} from "@/api/projectManage";
import {findMyProjectWorkflowList} from "@/api/myMission";
import {selectListWorkflow} from "@/api/minutesOfMeeting";

export default {
  name: "addTask1",
  data() {
    return {
      title: "新增",
      formData: {
        name: null,
        description: null,
        templateId: null,
        ownerId: null,
        planBeginTime: null,
        planEndTime: null,
        operationId: null,
        projectWorkflowId: null,
        projectWorkflowDetailId: null,
      },
      taskTemplateList: null,
      internalUseroptionsList: null,
      myProjectWorkflowMembers: null,
      rules: {
        name: [
          {required: true, message: "请输入任务名称", trigger: "blur"},
        ],
        description: [
          {required: true, message: "请输入任务描述", trigger: "blur"},
        ],
        templateId: [
          {required: true, message: "请选择任务模版", trigger: "change"},
        ],
        ownerId: [
          {required: true, message: "请选择任务负责人", trigger: "change"},
        ],
        operationId: [
          {required: false, message: "请选择操作", trigger: "change"},
        ],
        projectWorkflowId: [
          {required: true, message: '请选择业务进度表', trigger: 'change'}
        ],
        projectWorkflowDetailId: [
          {required: true, message: '请选择流程节点', trigger: 'change'}
        ]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      operationList: [
        {lable: "上传文件", id: "WFOP100000002"},
        {lable: "项目评估", id: "WFOP100000005"},
        {lable: "记录会议纪要", id: "WFOP100000007"},
      ],
      templateIdShow: false,
      myProjectWorkflowList: null,
      myProjectWorkflowDetailList: null,
    };
  },
  props: {
    type: String, //add, edit, detail
    workflowType: { // '', workflow, non-workflow
      type: String,
      default: ''
    },
    add1TaskDalog: Boolean,
    info: Object,
    relationList: Array,
    source: String,
  },
  mounted() {
    if (this.workflowType === 'workflow') {
      this.findMyProjectWorkflowList(true);

      if (this.type === 'add' && this.relationList && this.relationList.length > 0) {
        this.formData.projectWorkflowId = this.relationList.find(x => x.type === 2).targetId;
        this.formData.projectWorkflowDetailId = this.relationList.find(x => x.type === 1).targetId;
      }
    } else {
      if (this.type === 'edit' || this.type === 'detail') {
        this.findMyProjectWorkflowMembers(true);
      }
    }
  },
  watch: {
    info: {
      handler(newVal, old) {
        if (newVal) {
          if (this.type === "edit" || this.type === 'detail') {
            this.formData = this.info;
            console.log('formData', this.formData);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    formData: {
      handler(newName, oldName) {
        console.log(newName);
        if (newName) {
          if (newName.templateId) {
            this.rules.templateId[0].required = true;
            this.rules.name[0].required = false;
            this.rules.description[0].required = false;
          } else if (newName.name && newName.description) {
            this.rules.templateId[0].required = false;
          }

          if (this.workflowType === 'workflow') {
            this.rules.projectWorkflowId[0].required = true;
            this.rules.projectWorkflowDetailId[0].required = true;
          } else if (this.workflowType === 'non-workflow') {
            this.rules.projectWorkflowId[0].required = false;
            this.rules.projectWorkflowDetailId[0].required = false;
          }else  if (this.workflowType === '') {
            this.rules.projectWorkflowId[0].required = false;
            this.rules.projectWorkflowDetailId[0].required = false;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    source: {
      handler(newVal, old) {
        console.log(newVal);
        if (newVal == "newSchedule") {
          this.rules.operationId[0].required = true;
        } else {
          this.rules.operationId[0].required = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {},
  created() {
    // this.getQualityList();
    if (this.type === "edit") {
      this.title = "编辑";
    } else if (this.type === "add") {
      this.title = "新增";
    } else if (this.type === "detail") {
      this.title = "查看"
    }

    if (this.workflowType === 'workflow') {
      this.title += ' - 流程任务';
    } else if (this.workflowType === 'non-workflow') {
      this.title += ' - 非流程任务';
    }

    if (this.type === 'detail' || this.type === 'edit') {
      this.formData = this.info;
    }
  },
  methods: {
    changeTemplatShow(status) {
      console.log(status);
      if (status) {
        this.rules.templateId[0].required = true;
        this.rules.name[0].required = false;
        this.rules.description[0].required = false;
        this.rules.operationId[0].required = false;

        this.formData.name = null;
        this.formData.description = null;
      } else {
        this.rules.templateId[0].required = false;
        this.rules.name[0].required = true;
        this.rules.description[0].required = true;
        this.formData.templateId = null;
        if (this.source == "newSchedule") {
          this.rules.operationId[0].required = true;
        } else {
          this.rules.operationId[0].required = false;
        }
      }
    },
    choosePorject(id) {
      this.rules.name[0].required = false;
      this.rules.description[0].required = false;
      this.formData.name = null;
      this.formData.description = null;
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    //弹窗关闭
    dialogClose() {
      // this.formData = {};
      this.$emit("listenAdd1TaskClose", {type: "cancel", workflowType: this.workflowType, isClose: true});
    },
    selectListTaskTemplate(cb) {
      if (cb) {
        selectListTaskTemplate({enabled: 1, businessType: 2}).then(
          (response) => {
            if (response.success) {
              this.taskTemplateList = response.data;
            }
          }
        );
      }
    },
    addTask1() {
      if (this.formData.projectWorkflowId && this.formData.projectWorkflowDetailId) {
        let projectId = this.myProjectWorkflowList.find(x => x.id === this.formData.projectWorkflowId).projectId;
        this.formData.relationList = [
          {
            type: 1,
            targetId: this.formData.projectWorkflowDetailId,
          },
          {
            type: 2,
            targetId: this.formData.projectWorkflowId,
          },
          {
            type: 3,
            targetId: projectId,
          },
        ]
      } else if (this.relationList) {
        this.formData.relationList = this.relationList;
      }

      addTask1(this.formData).then((response) => {
        if (response.success) {
          this.$message({type: "success", message: "添加成功!"});
          this.formData = {};
          this.$emit("listenAdd1TaskClose", {
            type: "sure",
            workflowType: this.workflowType,
            isClose: true,
            info: response.data,
          });
        }
      });
    },
    editTask1() {
      editTask1(this.formData).then((response) => {
        if (response.success) {
          this.$message({type: "success", message: "编辑成功!"});
          this.formData = {};
          this.$emit("listenAdd1TaskClose", {type: "sure", workflowType: this.workflowType, isClose: true});
        }
      });
    },
    //确定
    sure() {
      this.$refs["editTask1"].validate((valid) => {
        // console.log("-------------valid");
        if (valid) {
          console.log("-------------valid" + valid);
          if (this.type == "add") {
            this.addTask1();
          } else if (this.type == "edit") {
            this.editTask1();
          }
        } else {
          console.log("-------------error");
        }
      });
    },

    //查找我参与的项目
    findMyProjectWorkflowList(show = true) {
      if (!show) return;
      findMyProjectWorkflowList({})
        .then(res => {
          if (res.success) {
            this.myProjectWorkflowList = res.data;
            this.selectListWorkflow();
            this.findMyProjectWorkflowMembers(true);
          }
        })
    },

    onMyProjectWorkflowListChange() {
      //请求project workflow detail
      this.formData.projectWorkflowDetailId = null;
      this.selectListWorkflow();
      this.findMyProjectWorkflowMembers(true);
    },

    selectListWorkflow(show = true) {
      if (!show) return;
      if (!this.formData.projectWorkflowId) return;

      let param = {
        projectWorkflowId: this.formData.projectWorkflowId
      }
      selectListWorkflow(param)
        .then(res => {
          if (res.success) {
            this.myProjectWorkflowDetailList = res.data;
            let currPjwfDetail = this.myProjectWorkflowDetailList.find(x => x.id === this.formData.projectWorkflowDetailId);
            if (!currPjwfDetail) {
              this.formData.projectWorkflowDetailId = null;
            }
          }
        })
    },
    //查找我参与项目的成员，如果我是PM则可以看到所有成员，否则只能看到自己
    findMyProjectWorkflowMembers(show) {
      if (!show) return;
      let param = {
        id: this.formData.projectWorkflowId
      }
      findMyProjectWorkflowMembers(param)
        .then(res => {
          if (res.success) {
            this.myProjectWorkflowMembers = res.data;
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}

.red {
  color: red;
}

.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;

  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: right !important;
    margin-right: 40px;
  }

  .el-form-item {
    text-align: left;

    // display: flex;
    width: 48%;
    // float: left;
    min-height: 1rem;

    .el-select {
      width: 100% !important;
    }

    .el-input-number {
      width: 100% !important;
      text-align: left !important;

      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
</style>
