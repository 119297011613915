<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-03-15 17:15:35
 * @LastEditTime: 2022-06-22 17:12:06
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/myMission/businessProcess/businessProcessDetailTab.vue
-->
<template>
  <div class="details" v-loading="loading">
    <div v-show="!loading">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <el-form>
        <el-row type="flex" justify="end">
          <el-form-item v-if="detail.navButton != null">
            <el-button-group class="pr-5">
              <el-button
                v-if="detail.navButton != null"
                type="primary"
                icon="el-icon-arrow-left"
                @click="changeProject('previous')"
                :disabled="detail.navButton.prevId == null"
                >上一项目</el-button
              >
              <el-button
                v-if="detail.navButton != null"
                type="primary"
                @click="changeProject('next')"
                :disabled="detail.navButton.nextId == null"
                >下一项目<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </el-button-group>
            <el-button
              :type="detail.isFavorite == 1 ? '' : 'primary'"
              @click="followProject"
              >{{ detail.isFavorite == 1 ? "取消关注" : "关注" }}</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
      <el-tabs
        type="border-card"
        v-model="activeName"
        @tab-click="tabHandleClick"
        v-show="!loading"
      >
        <el-tab-pane label="基本资料">
          <template v-if="dataAuth.baseInfo">
            <ul class="bar" style="list-style-type: none">
              <li>
                <b>
                  {{
                    detail.virtualType == "organization" ? "投资机构" : "项目名"
                  }}
                  ：</b
                >
                <span>{{ detail.projectName }}</span>
              </li>
              <li>
                <b>公司：</b>
                <span>{{ detail.principalName }}</span>
              </li>
              <li>
                <b>业务流程：</b>
                <span>{{ detail.name }}</span
                ><span v-show="detail.workflowVersion" class="version"
                  >版本{{ detail.workflowVersion }}</span
                >
              </li>
              <li>
                <b>任务推进节点：</b>
                <span class="status2">{{
                  detail.currentWorkflowDetailName
                }}</span>
              </li>

              <li v-show="detail.evaluationTplList">
                <b>评估模版：</b>
                <div
                  style="padding: 0 2px"
                  v-for="(item, index) in detail.evaluationTplList"
                  :key="index"
                >
                  <div v-if="detail.evaluationTplList.length == 1">
                    {{ item.name }}
                  </div>
                  <div v-else>{{ index + 1 }}:{{ item.name }}</div>
                </div>
              </li>
              <li>
                <b>业务负责人：</b>
                <span>{{ detail.pmName }}</span>
              </li>
              <li v-if="detail.memberList">
                <b>业务团队成员：</b>
                <div>
                  <el-row
                    style="padding: 0 2px"
                    v-for="(item, index) in detail.memberList"
                    :key="index"
                  >
                    <el-col>
                      <span>{{
                        detail.memberList.length > 1
                          ? index + 1 + ":" + item.realName
                          : item.realName
                      }}</span>
                      <el-tag
                        v-for="(item2, index2) in item.roleNameList"
                        :key="index2"
                        style="width: auto; margin-right: 2px"
                        size="mini"
                      >
                        {{ item2 }}
                      </el-tag>
                    </el-col>
                  </el-row>
                </div>
              </li>
              <li>
                <b>创建时间：</b>
                <span>{{ detail.createDate | formatDateFilter }}</span>
              </li>
              <li>
                <b>状态：</b>
                <span
                  :class="[
                    detail.status == 1
                      ? 'status1'
                      : detail.status == 2
                      ? 'status2'
                      : detail.status == 3
                      ? 'status3'
                      : detail.status == 4
                      ? 'status4'
                      : detail.status == 5
                      ? 'status5'
                      : '',
                  ]"
                  >{{ detail.status | businessStatus }}</span
                >
              </li>
              <li class="width100">
                <b>投融资需求：</b>
                <span>{{ detail.investDemand }}</span>
              </li>
              <li class="width100">
                <b>投融资进展：</b>
                <span>{{ detail.investProgress }}</span>
              </li>
              <li class="width100">
                <b>进展：</b>
                <div class="">
                  <el-progress
                    :percentage="detail.propelRate"
                    :color="customColorMethod"
                  ></el-progress>
                </div>
              </li>
            </ul>
          </template>
          <template v-else-if="dataAuth.baseInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  name="daa-base-info"
                  :info="dataAuthInfo.baseInfo"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="企业工商信息">
          <template v-if="dataAuth.principalRegInfo">
            <div accordion v-show="detail.principalRegInfo != null">
              <template slot="title">
                企业工商信息<i class="header-icon el-icon-info"></i>
              </template>
              <!-- <div class="flexJfAc" v-if="detail.principalRegInfo">
                <h5>去企查查看更多信息？：</h5>
                <el-link :href="principalRegInfoNameLink" target="_blank">{{
                  detail.principalRegInfo.name
                }}</el-link>
              </div> -->
              <ul
                v-if="detail.principalRegInfo != null"
                class="bar"
                style="list-style-type: none"
              >
                <li class="width100">
                  <b>公司名：</b>
                  <span class="flexJfAc">
                    {{ detail.principalRegInfo.name }}
                    <el-link
                      class="link"
                      type="primary"
                      :href="principalRegInfoNameLink"
                      target="_blank"
                      >去企查查看更多信息？</el-link
                    >
                  </span>
                </li>
                <!-- <li>
        <b>公司ID:</b>
        {{detail.companyId}}
      </li>-->
                <li>
                  <b>法人：</b>
                  <span> {{ detail.principalRegInfo.legalPersonName }}</span>
                </li>
                <li>
                  <b>公司logo:</b>
                  <img
                    :src="detail.principalRegInfo.logo"
                    width="80px"
                    height="80px"
                    alt
                  />
                </li>

                <li>
                  <b>注册资本：</b>
                  <span>{{ detail.principalRegInfo.regCapital }}</span>
                </li>
                <li>
                  <b>注册时间：</b>
                  <span>
                    {{
                      detail.principalRegInfo.fromTime | formatDateFilter
                    }}</span
                  >

                  <!-- {{detail.fromTime}} -->
                </li>
                <li>
                  <b>统一信用代码:</b>
                  <span>{{ detail.principalRegInfo.creditCode }}</span>
                </li>
                <li>
                  <b>企业类型:</b>
                  <span> {{ detail.principalRegInfo.companyOrgType }}</span>
                </li>

                <li>
                  <b>工商注册号:</b>
                  <span> {{ detail.principalRegInfo.regNumber }}</span>
                </li>
                <li>
                  <b>组织机构代码:</b>
                  <span>{{ detail.principalRegInfo.orgNumber }}</span>
                </li>
                <li>
                  <b>营业期限:</b>
                  <span>
                    {{
                      detail.principalRegInfo.toTime | formatDateFilter
                    }}</span
                  >
                </li>
                <li>
                  <b>行业:</b>
                  <span> {{ detail.principalRegInfo.industry }}</span>
                </li>
                <li class="width100">
                  <b>经营范围:</b>
                  <span>{{ detail.principalRegInfo.businessScope }}</span>
                </li>
                <li>
                  <b>注册地址:</b>
                  <span>{{ detail.principalRegInfo.regLocation }}</span>
                </li>
                <li>
                  <b>联系电话:</b>
                  <span>{{ detail.principalRegInfo.phoneNumber }}</span>
                </li>
                <li>
                  <b>联系电话:</b>
                  <span>{{ detail.principalRegInfo.phoneNumber }}</span>
                </li>
                <li>
                  <b>轮次:</b>
                  <span> {{ detail.rounds }}</span>
                </li>
                <li>
                  <b>经营状态:</b>
                  <span>{{ detail.principalRegInfo.regStatus }}</span>
                </li>
              </ul>

              <div v-show="detail.principalOrgInfoList != null">
                <template slot="title">
                  企业工商信息<i class="header-icon el-icon-info"></i>
                </template>
                <div
                  class=""
                  v-for="(item, index) in detail.principalOrgInfoList"
                  :key="index"
                >
                  <h4>{{ index + 1 }}.{{ item.name }}</h4>
                  <ul class="bar" style="list-style-type: none">
                    <li>
                      <b>公司名：</b>
                      <span>{{ item.name }}</span>
                    </li>
                    <!-- <li>
        <b>公司ID:</b>
        {{detail.companyId}}
      </li>-->
                    <li>
                      <b>法人：</b>
                      <span> {{ item.legalPersonName }}</span>
                    </li>
                    <li>
                      <b>公司logo:</b>
                      <img :src="item.logo" width="80px" height="80px" alt />
                    </li>

                    <li>
                      <b>注册资本：</b>
                      <span>{{ item.regCapital }}</span>
                    </li>
                    <li>
                      <b>注册时间：</b>
                      <span> {{ item.fromTime | formatDateFilter }}</span>

                      <!-- {{detail.fromTime}} -->
                    </li>
                    <li>
                      <b>统一信用代码:</b>
                      <span>{{ item.creditCode }}</span>
                    </li>
                    <li>
                      <b>企业类型:</b>
                      <span> {{ item.companyOrgType }}</span>
                    </li>

                    <li>
                      <b>工商注册号:</b>
                      <span> {{ item.regNumber }}</span>
                    </li>
                    <li>
                      <b>组织机构代码:</b>
                      <span>{{ item.orgNumber }}</span>
                    </li>
                    <li>
                      <b>营业期限:</b>
                      <span> {{ item.toTime | formatDateFilter }}</span>
                    </li>
                    <li>
                      <b>行业:</b>
                      <span> {{ item.industry }}</span>
                    </li>
                    <li class="width100">
                      <b>经营范围:</b>
                      <span>{{ item.businessScope }}</span>
                    </li>
                    <li>
                      <b>注册地址:</b>
                      <span>{{ item.regLocation }}</span>
                    </li>
                    <li>
                      <b>联系电话:</b>
                      <span>{{ item.phoneNumber }}</span>
                    </li>
                    <li>
                      <b>联系电话:</b>
                      <span>{{ item.phoneNumber }}</span>
                    </li>
                    <li>
                      <b>轮次:</b>
                      <span> {{ detail.rounds }}</span>
                    </li>
                    <li>
                      <b>经营状态:</b>
                      <span>{{ item.regStatus }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="dataAuth.principalRegInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  name="daa-principal-reg-info"
                  :info="dataAuthInfo.principalRegInfo"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="项目经营信息" name="projectOperation">
          <template>
            <template slot="title">
              项目经营信息<i class="header-icon el-icon-info"></i>
            </template>
            <div class="other">
              <el-tabs
                type="border-card"
                @tab-click="handleClick"
                v-model="activeName2"
              >
                <el-tab-pane label="网店相关信息" name="onlineShop">
                  <ul class="">
                    <li class="flexJfAc onlineShopList">
                      <b>网店：</b>
                      <div
                        style="padding: 0 2px"
                        v-for="(item, index) in detail.onlineShopList"
                        :key="index"
                      >
                        <div
                          class="pointer"
                          v-if="detail.onlineShopList.length == 1"
                          @click="getOnlineShopOne(item)"
                        >
                          {{
                            item.type == 1
                              ? "淘宝"
                              : item.type == 2
                              ? "京东"
                              : ""
                          }}
                          <el-button size="mini">{{ item.name }}</el-button>
                        </div>
                        <div
                          v-else
                          @click="getOnlineShopOne(item)"
                          class="pointer"
                        >
                          <span class="forIndex">{{ index + 1 }}</span
                          >.{{
                            item.type == 1
                              ? "淘宝"
                              : item.type == 2
                              ? "京东"
                              : ""
                          }}
                          <el-button size="mini">{{ item.name }}</el-button>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div
                    class="a"
                    v-if="tableData != null && onlineShopOneType == 1"
                  >
                    <!-- 表格 -->
                    <el-table border :data="tableData">
                      <el-table-column label="商品标题" prop="title">
                        <template slot-scope="scope">
                          <el-link
                            :href="scope.row.url"
                            target="_blank"
                            :underline="false"
                            >{{ scope.row.title }}</el-link
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="原价"
                        prop="original_price"
                        width="110"
                      ></el-table-column>
                      <el-table-column
                        label="现价"
                        prop="price"
                        width="110"
                      ></el-table-column>
                      <el-table-column
                        label="销量"
                        prop="sold"
                        width="110"
                      ></el-table-column>
                      <el-table-column label="商品图" prop="img" width="100">
                        <template slot-scope="scope">
                          <img class="picImg" :src="scope.row.img" alt="" />
                        </template>
                      </el-table-column>
                      <el-table-column label="商品链接" prop="url" width="100">
                        <template slot-scope="scope">
                          <el-link
                            :href="scope.row.url"
                            target="_blank"
                            :underline="false"
                            >商品链接</el-link
                          >
                        </template>
                      </el-table-column>
                      <!-- <el-table-column label="操作" width="125">
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="openGoodsFeedbackList(scope.row)"
                        >查看商品评价</el-button
                      >
                    </template>
                  </el-table-column> -->
                    </el-table>

                    <div class="page-info-class">
                      <el-pagination
                        @size-change="handleSizeChangeProductList"
                        @current-change="handleCurrentChangeProductList"
                        :current-page="this.queryParamsShoProducts.pageNum"
                        :page-sizes="[10, 20]"
                        :page-size="this.queryParamsShoProducts.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.queryParamsShoProducts.total"
                      ></el-pagination>
                    </div>
                  </div>
                  <div
                    class="a"
                    v-else-if="tableData != null && onlineShopOneType == 2"
                  >
                    <!-- 表格 -->
                    <el-table border :data="tableData">
                      <el-table-column label="商品标题" prop="warename">
                        <template slot-scope="scope">
                          <el-link
                            :href="scope.row.toItemLink"
                            target="_blank"
                            :underline="false"
                            >{{ scope.row.Content.warename }}</el-link
                          >
                        </template>
                      </el-table-column>
                      <el-table-column label="属性" prop="CustomAttrList">
                        <template slot-scope="scope">
                          {{ scope.row.Content.CustomAttrList }}
                        </template>
                      </el-table-column>
                      <!-- <el-table-column label="颜色" prop="color">
              <template slot-scope="scope">
                {{ scope.row.Content.color }}
              </template>
            </el-table-column> -->
                      <el-table-column
                        label="价格"
                        prop="dredisprice"
                        width="110"
                      ></el-table-column>
                      <!-- <el-table-column
              label="现价"
              prop="price"
              width="110"
            ></el-table-column> -->
                      <!-- <el-table-column
              label="销量"
              prop="sold"
              width="110"
            ></el-table-column> -->
                      <el-table-column label="商品图" prop="img" width="100">
                        <template slot-scope="scope">
                          <img
                            class="picImg"
                            :src="scope.row.Content.imageurl"
                            alt=""
                          />
                        </template>
                      </el-table-column>
                      <el-table-column label="商品链接" prop="url" width="100">
                        <template slot-scope="scope">
                          <el-link
                            :href="scope.row.toItemLink"
                            target="_blank"
                            :underline="false"
                            >商品链接</el-link
                          >
                        </template>
                      </el-table-column>
                      <!-- <el-table-column label="操作" width="125">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openGoodsFeedbackList(scope.row)"
                  >查看商品评价</el-button
                >
              </template>
            </el-table-column> -->
                    </el-table>

                    <div class="page-info-class">
                      <el-pagination
                        @size-change="handleSizeChangeProductList"
                        @current-change="handleCurrentChangeProductList"
                        :current-page="this.queryParamsShoProducts.pageNum"
                        :page-sizes="[10, 20]"
                        :page-size="this.queryParamsShoProducts.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.queryParamsShoProducts.total"
                      ></el-pagination>
                    </div>
                  </div>
                  <div v-else class="b">
                    <el-empty name="empty" description="暂无数据"></el-empty>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="新闻舆情" name="news">
                  <el-table border :data="newList">
                    <el-table-column
                      label="来源"
                      prop="Source"
                      width="70"
                    ></el-table-column>
                    <el-table-column label="新闻标题" prop="Title">
                      <template slot-scope="scope">
                        <!-- <el-popover
                      placement="top-start"
                      title="描述"
                      width="400"
                      trigger="hover"
                      :content="scope.row.Title"
                    >

                    </el-popover> -->
                        <el-link
                          :href="scope.row.Url"
                          target="_blank"
                          :underline="false"
                        >
                          <span class="beyondDisplay1">
                            {{ scope.row.Title }}
                          </span></el-link
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="新闻标签"
                      prop="NewsTags"
                    ></el-table-column>
                    <el-table-column
                      label="类别"
                      prop="Category"
                    ></el-table-column>
                    <el-table-column
                      label="发布时间"
                      prop="PublishTime"
                      width="100"
                    ></el-table-column>
                  </el-table>
                  <div class="page-info-class">
                    <el-pagination
                      @size-change="handleSizeChangeNews"
                      @current-change="handleCurrentChangeNews"
                      :current-page="this.queryParamsNewList.pageNum"
                      :page-size="this.queryParamsNewList.pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.queryParamsNewList.total"
                    ></el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="竞品信息" name="product">
                  <el-table border :data="productList">
                    <el-table-column
                      label="名称"
                      prop="ProductName"
                    ></el-table-column>
                    <el-table-column
                      label="描述"
                      prop="Description"
                      width="160"
                    >
                      <template slot-scope="scope">
                        <el-popover
                          placement="top-start"
                          title="描述"
                          width="400"
                          trigger="hover"
                          :content="scope.row.Description"
                        >
                          <span slot="reference" class="beyondDisplay1">
                            {{ scope.row.Description }}
                          </span>
                        </el-popover>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="区域"
                      prop="ProvinceDesc"
                    ></el-table-column>
                    <el-table-column
                      label="成立时间"
                      prop="StartDate"
                    ></el-table-column>
                    <el-table-column
                      label="融资轮次"
                      prop="Round"
                    ></el-table-column>
                    <el-table-column
                      label="最近融资时间"
                      prop="RoundDate"
                    ></el-table-column>
                    <el-table-column
                      label="最近融资金额"
                      prop="Amount"
                    ></el-table-column>
                    <el-table-column label="logo" prop="createDate" width="160">
                      <template slot-scope="scope">
                        <img
                          style="width: 70px"
                          :src="scope.row.ImageUrl"
                          alt=""
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="fileList"
                      width="130"
                      align="left"
                      label="任务操作"
                      fixed="right"
                    >
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          round
                          type="primary"
                          @click="openCompetitor(scope.row)"
                        >
                          查看竞品信息
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="page-info-class">
                    <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="this.queryParamsProduct.pageNum"
                      :page-size="this.queryParamsProduct.pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.queryParamsProduct.total"
                    ></el-pagination>
                  </div>

                  <el-table border :data="productRecommenlist">
                    <el-table-column
                      label="名称"
                      prop="ProductName"
                    ></el-table-column>
                    <el-table-column
                      label="描述"
                      prop="Description"
                      width="160"
                    >
                      <template slot-scope="scope">
                        <el-popover
                          placement="top-start"
                          title="描述"
                          width="400"
                          trigger="hover"
                          :content="scope.row.Description"
                        >
                          <span slot="reference" class="beyondDisplay1">
                            {{ scope.row.Description }}
                          </span>
                        </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="区域"
                      prop="RelatedComInfo.Name"
                    ></el-table-column>
                    <el-table-column
                      label="区域"
                      prop="Location"
                    ></el-table-column>
                    <el-table-column
                      label="成立时间"
                      prop="StartDate"
                    ></el-table-column>
                    <el-table-column
                      label="融资轮次"
                      prop="RoundDesc"
                    ></el-table-column>
                    <!-- <el-table-column
                  prop="fileList"
                  width="130"
                  align="left"
                  label="任务操作"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      round
                      type="primary"
                      @click="openCompetitor(scope.row)"
                    >
                      查看竞品信息
                    </el-button>
                  </template>
                </el-table-column> -->
                  </el-table>
                </el-tab-pane>

                <el-tab-pane label="招聘信息" name="recruitment">
                  <el-table border :data="recruitmentList">
                    <el-table-column
                      label="企业名称"
                      prop="CompanyName"
                    ></el-table-column>
                    <el-table-column
                      label="职位"
                      prop="Title"
                    ></el-table-column>
                    <el-table-column
                      label="学历"
                      prop="Education"
                    ></el-table-column>
                    <el-table-column
                      label="经验"
                      prop="Experience"
                    ></el-table-column>

                    <el-table-column
                      label="发布日期"
                      prop="PublishDate"
                    ></el-table-column>
                    <el-table-column
                      label="区域"
                      prop="ProvinceDesc"
                    ></el-table-column>
                  </el-table>
                  <div class="page-info-class">
                    <el-pagination
                      @size-change="handleSizeChangeRecruitment"
                      @current-change="handleCurrentChangeRecruitment"
                      :current-page="this.queryParamsRecruitment.pageNum"
                      :page-size="this.queryParamsRecruitment.pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.queryParamsRecruitment.total"
                    ></el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="百度资讯" name="baidu"> </el-tab-pane>
                <el-tab-pane label="微博资讯" name="weibo">
                  <div class="baidu" v-if="iframeUrlWeibo">
                    <iframe
                      ref="weibo"
                      :src="iframeUrlWeibo"
                      frameborder="0"
                      style="width: 100%; height: 75vh"
                    ></iframe>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="企查查" name="qcc">
                  <div class="baidu" v-if="iframeUrlQcc">
                    <iframe
                      ref="qcc"
                      :src="iframeUrlQcc"
                      frameborder="0"
                      style="width: 100%; height: 75vh"
                    ></iframe>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="抖音" name="tiktok">
                  <tiktok
                    v-if="tiktokKeyword"
                    :type="null"
                    :searchKeywords="tiktokKeyword"
                  ></tiktok>
                </el-tab-pane>
              </el-tabs>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane label="项目文件" name="documentList">
          <template v-if="dataAuth.documentFile || dataAuth.dingTalkFile">
            <template slot="title">
              项目文件<i class="header-icon el-icon-document"></i>
            </template>
            <documentList
              v-if="documentListShow"
              :type="2"
              :projectInfo="detail2"
              :dataAuth="dataAuth"
              :dataAuthInfo="dataAuthInfo"
            ></documentList>
          </template>
          <template
            v-else-if="
              dataAuth.documentFile === false && dataAuth.dingTalkFile === false
            "
          >
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  v-if="dataAuth.documentFile === false"
                  title="暂无权限查看项目文件"
                  :info="dataAuthInfo.documentFile"
                />
                <data-auth-apply
                  v-if="dataAuth.dingTalkFile === false"
                  title="暂无权限查看网盘文件"
                  :info="dataAuthInfo.dingTalkFile"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="任务">
          <template v-if="dataAuth.taskInfo">
            <el-table
              border
              row-key="id"
              style="width: 100%"
              :data="detail.taskList"
              @current-change="handleCurrentChange"
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center' }"
              :span-method="taskObjectSpanMethod"
            >
              <!-- <el-table-column
          label="流程版本"
          prop="workflowVersion"
        ></el-table-column> -->
              <el-table-column
                label="流程节点"
                prop="nodeName"
              ></el-table-column>
              <el-table-column
                label="任务"
                prop="name"
                width=""
              ></el-table-column>
              <el-table-column
                label="任务负责人"
                prop="ownerName"
                width="93"
              ></el-table-column>

              <el-table-column label="任务状态" prop="status" width="78">
                <template slot-scope="scope">
                  <span
                    :class="[
                      scope.row.status == 1
                        ? 'status1'
                        : scope.row.status == 2
                        ? 'status2'
                        : scope.row.status == 3
                        ? 'status3'
                        : scope.row.status == 4
                        ? 'status4'
                        : scope.row.status == 5
                        ? 'status5'
                        : '',
                    ]"
                    >{{ scope.row.status | businessStatus }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="产出" prop="meetingList" width="260">
                <template slot-scope="scope">
                  <div class="flexFcJf">
                    <div class="flexJfAc" v-if="scope.row.evaluateRecordList">
                      <h5 class="meetingLeft">评估：</h5>
                      <div>
                        <p
                          v-for="(item, index) in scope.row.evaluateRecordList"
                          :key="index"
                        >
                          <el-link
                            type="primary"
                            @click="openContent('项目评估', item, '1')"
                            slot="reference"
                            size="mini"
                            class=""
                            >评估{{ index + 1 }}</el-link
                          >
                        </p>
                      </div>
                    </div>
                    <div class="flexJfAc" v-if="scope.row.meetingList">
                      <h5 class="meetingLeft">项目会议：</h5>
                      <div>
                        <p
                          v-for="(item, index) in scope.row.meetingList"
                          :key="index"
                        >
                          <el-link
                            type="primary"
                            @click="openContent('项目会议', item, '2')"
                            slot="reference"
                            size="mini"
                            >{{ index + 1 }}.{{ item.subject }}</el-link
                          >
                        </p>
                      </div>
                    </div>
                    <div class="flexJfAc" v-if="scope.row.fileList">
                      <h5 class="meetingLeft">文件：</h5>
                      <div>
                        <el-link
                          style="cursor: pointer"
                          v-for="(item, index) in scope.row.fileList"
                          :key="index"
                          @click="handleExport(item)"
                        >
                          <p v-if="item.originalFileName">
                            {{ index + 1 }}.{{ item.originalFileName }}
                          </p>
                          <p v-else>{{ index + 1 }}.{{ item.name }}</p>
                        </el-link>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column label="项目会议" prop="progress">
            <template slot-scope="scope">
              <p v-for="(item, index) in scope.row.meetingList" :key="index">
                <el-link
                  type="primary"
                  @click="openContent('项目会议', item, '2')"
                  slot="reference"
                  size="mini"
                  >{{ item.subject }}</el-link
                >
              </p>
            </template>
          </el-table-column>
          <el-table-column label="文件" prop="fileList">
            <template slot-scope="scope">
              <a
                href="#"
                style="cursor: pointer"
                v-for="(item, index) in scope.row.fileList"
                :key="index"
                @click="handleExport(item)"
              >
                <p v-if="item.originalFileName">
                  {{ index + 1 }}.{{ item.originalFileName }}
                </p>
                <p v-else>{{ index + 1 }}.{{ item.name }}</p>
              </a>
            </template>
          </el-table-column> -->
              <el-table-column label="操作" fixed="right" width="325">
                <template slot-scope="scope">
                  <el-button
                    v-permission="['admin:task:add_instruction']"
                    type="primary"
                    size="mini"
                    @click="addTask(scope.row)"
                    class="taskButton"
                    >添加批示任务
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    class="taskButton"
                    @click="openTaskCommen(scope.row)"
                    >评论
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-connection"
                    class="taskButton"
                    @click="opeMyMission2(scope.row)"
                    >我的任务
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-connection"
                    class="taskButton"
                    @click="openTaskManagement(scope.row)"
                    >任务管理
                  </el-button>
                  <!-- <el-popover
                trigger="hover"
                placement="top"
                width="280"
                :ref="scope.$index"
              >
              
                <el-button
                  icon="el-icon-view"
                  slot="reference"
                  style="margin: 0 0 0 10px; font-size: 18px"
                  size="small"
                  type="text"
                ></el-button>
              </el-popover> -->
                </template>
              </el-table-column>
              <!-- <el-table-column label="风险及应对" prop="remark"></el-table-column>
        <el-table-column label="计划开始日期" prop="createDate" width="155">
          <template slot-scope="scope">
            <span>{{ scope.row.planBeginTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="计划结束日期" prop="createDate" width="155">
          <template slot-scope="scope">
            <span>{{ scope.row.planEndTime | formatDateFilter }}</span>
          </template>
        </el-table-column> -->
            </el-table>
          </template>
          <template v-else-if="dataAuth.taskInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  name="daa-task-info"
                  :info="dataAuthInfo.taskInfo"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="评论">
          <div class="comment" v-if="pwfId">
            <comment :subjectType="subjectType" :subjectId="pwfId"></comment>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogClose">确 定</el-button>
    </span> -->

    <!-- 评估 -->
    <el-dialog
      :title="contentDialogTitle"
      :visible.sync="contentDialog"
      width="60%"
      :before-close="handleContentClose"
      append-to-body
      center
    >
      <div class="content" v-show="contentDialogType == 2">
        <span v-html="content">{{ content }}</span>
        <!-- 评论 -->
        <el-divider content-position="center">评论</el-divider>
        <div class="comment" v-if="contentDialog">
          <comment
            :subjectType="commentMeetingType"
            :subjectId="commentMeetingId"
          ></comment>
        </div>
      </div>

      <div v-show="contentDialogType == 1">
        <div class="bar" style="text-align: left; margin-top: 20px">
          <li>
            <b>项目：</b>
            <span>{{ evaluateRecordIfno.projectName }}</span>
          </li>
          <li>
            <b>评估时点：</b>
            <span>{{ moduleScoreHeaderName }}</span>
          </li>
          <li>
            <b>流程：</b>
            <span>{{ evaluateRecordIfno.projectWorkflowName }}</span>
          </li>
          <li>
            <b>评估人：</b>
            <span>{{ evaluateRecordIfno.evaluatorName }}</span>
          </li>
          <li>
            <b>记录人：</b>
            <span>{{ evaluateRecordIfno.recorderName }}</span>
          </li>
          <li>
            <b>时间：</b>
            <span>{{ evaluateRecordIfno.beginTime | formatDateFilter }}</span>
          </li>
        </div>
        <!-- <h4 v-show="evaluateRecordIfno.projectName">
          {{ evaluateRecordIfno.projectName }}：{{
            evaluateRecordIfno.projectWorkflowName
          }}-{{ evaluateRecordIfno.templateName }}
        </h4>
        <h4 style="text-align: left; margin-top: 20px">
          {{ moduleScoreHeaderName }}
        </h4>
        <h4 style="text-align: left; margin-top: 20px">
          时间：{{ evaluateRecordIfno.beginTime | formatDateFilter }}
        </h4> -->

        <h4 style="text-align: left; margin-top: 20px">评估记录：</h4>
        <el-table
          v-loading="tableLoading"
          ref="multipleTable"
          width="100%"
          border
          :span-method="arraySpanMethod"
          :data="evaluateRecordTableData"
          style="width: 100%; margin-top: 10px"
        >
          <el-table-column label="模块" align="center" fixed="left">
            <template slot-scope="scope" width="160">
              {{ scope.row.moduleName }}
              <br />
              <span class="explain">
                {{ scope.row.moduleExplain }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="细分模块" align="center" fixed="left">
            <template slot-scope="scope" width="160">
              {{ scope.row.subModuleName }}
              <br />
              <span class="explain">
                {{ scope.row.subModuleExplain }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="评估要素"
            width="180"
            align="center"
            fixed="left"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.question }}</p>
            </template>
          </el-table-column>
          <el-table-column label="评估得分" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.score }}</p>
            </template>
          </el-table-column>
          <el-table-column label="模块评估得分" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.moduleScore }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="meetingContentDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="contentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <addTask1
      v-if="add1TaskDalog"
      :add1TaskDalog="add1TaskDalog"
      :type="add1TaskType"
      :relationList="relationList"
      v-on:listenAdd1TaskClose="listenAdd1TaskClose"
    ></addTask1>
    <el-dialog
      title="预览"
      append-to-body
      center
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div class="pdfUrlF" v-if="pdfUrl"></div>
      <div class="imgUrl" v-if="imgUrl">
        <el-image ref="imgUrl" :src="imgUrl">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <evaluateRecordTable
      v-if="evaluateRecordTableDialog"
      v-on:listenEvaluateRecordTableClose="listenEvaluateRecordTableClose"
      :evaluateRecordTableDialog="evaluateRecordTableDialog"
      :id="evaluateRecordId"
    >
    </evaluateRecordTable>

    <!-- 任务评论 -->
    <el-dialog
      title="任务评论"
      :visible.sync="taskCommentDialog"
      width="60%"
      :before-close="taskContentClose"
      append-to-body
    >
      <!-- 评论 -->
      <!-- <el-divider content-position="center">评论</el-divider> -->
      <div class="comment" v-if="taskCommentDialog">
        <comment :subjectType="taskSubjectType" :subjectId="taskId"></comment>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="taskCommentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from "@/common/date";
import {
  projecTrackAllDetail,
  evaluationRecord,
  getEvaluationRecordDetail,
  projectAssignUserList,
  followProject,
  unFollowProject,
  findProjectAllDetail,
} from "@/api/projectManage";
import { getExternalData } from "@/api/externalData";

import { editTask, taskStatusEdit } from "@/api/myMission";
import addTask1 from "@/components/myMission/addTask/addTask1";
import evaluateRecordTable from "@/components/myMission/evaluationList/evaluateRecordTable";
import documentList from "@/components/myMission/projectDocuments/documentList.vue";
import comment from "@/components/comment/comment.vue";
import DataAuthApply from "@/components/dataAuth/dataAuthApply";
import tiktok from "@/components/onlineShopGoods/tiktok.vue";

export default {
  name: "detailsDialog",
  props: {
    type: String,
    businessProcessDetailDialog: Boolean,
    // id: String,
    // projectId: String,
    // queryParams: Object,
    // projectWorkflowId:String,
  },
  data() {
    return {
      id: null,
      pwfId: null,
      evaluateRecordTableDialog: false,
      evaluateRecordId: null,
      detail: {
        onlineShopList: null,
      },
      detail2: {},
      activeName: null,
      activeName2: "onlineShop",
      loading: false,
      title: null,
      contentDialog: false,
      contentDialogTitle: null,
      content: null,
      evaluateRecordTableData: [],
      needMergeArr: ["moduleName", "subModuleName"], // 有合并项的列
      rowMergeArrs: {}, // 包含需要一个或多个合并项信息的对象
      textArr: [],
      spanArr: [],
      spanArrTask: [],
      tableLoading: false,
      indexOf: [0],
      pos: 0,
      posTask: 0,
      chnNumChar: ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"],
      chnUnitSection: ["", "万", "亿", "万亿", "亿亿"],
      chnUnitChar: ["", "十", "百", "千"],

      evaluationTimes: 0,
      contentDialogType: null,
      evaluateRecordIfno: {
        projectName: null,
      },
      scoreHeaderName: null,
      moduleScoreHeaderName: null,
      add1TaskDalog: false,
      add1TaskType: null,
      relationList: null,
      dialogVisible: false,
      pdfUrl: null,
      imgUrl: null,
      scale: 100,
      internalUseroptionsList: [],
      userId: null,
      taskStatusOptions: [
        { value: 1, label: "待启动", color: "color:#409EFF" },
        { value: 2, label: "进行中", color: "color:#F56C6C" },
        { value: 3, label: "已完成", color: "color:#67C23A" },
        { value: 4, label: "终止", color: "color:#909399" },
        { value: 5, label: "暂停", color: "color:#E6A23C" },
      ],
      documentListShow: false,
      statusSelectShow: false,
      currentRow: null,
      dataAuth: {},
      subjectType: "BUSINESS_PROCESS",
      dataAuthInfo: null,
      commentMeetingType: "MEETING_DETAIL",
      commentMeetingId: null,
      taskId: null,
      taskSubjectType: "TASK_DETAIL",
      taskCommentDialog: false,
      principalRegInfoNameLink: null,
      projectId: null,
      queryParams: {},
      iframeUrlWeibo: null,
      iframeUrlQcc: null,
      tabName: null,
      tabName2: "onlineShop",
      tiktokKeyword: null,
      queryParamsNewList: {
        providerName: "qcc.co.news-list",
        searchKey: null,
        emotionType: null,
        category: null,
        startDate: null,
        endDate: null,
        pageNum: 1,
        pageSize: 50,
      },
      newList: [],
      queryParamsProduct: {
        providerName: "qcc.co.product-list",
        searchKey: null,
        pageNum: 1,
        pageSize: 50,
      },
      productList: [],
      queryParamsRecruitment: {
        providerName: "qcc.co.recruitment-list",
        searchKey: null,
        pageNum: 1,
        pageSize: 50,
      },
      recruitmentList: [],
      queryParamsProductRecommen: {
        providerName: "qcc.co.product-recommend-list",
        id: null,
      },
      productRecommenlist: [],
      queryParamsShoProducts: {
        startPrice: null,
        endPrice: null,
        keywords: "",
        providerName: "tb.shop.goods-list",
        shopId: null,
        sort: 3,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      tableData: [],
      onlineShopOneType: null,
    };
  },
  components: {
    DataAuthApply,
    addTask1,
    evaluateRecordTable,
    documentList,
    comment,
    tiktok,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      if (time) return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    businessStatus(value) {
      switch (value) {
        case 0:
          return "未知";
        case 1:
          return "待启动";
        case 2:
          return "进行中";
        case 3:
          return "已完成";
        case 4:
          return "终止";
        case 5:
          return "暂停";
      }
    },
  },
  computed: {},
  watch: {
    id: {
      handler(newVal, old) {
        if (newVal) {
          // this.pwfId = JSON.parse(JSON.stringify(newVal));
          this.userId = window.localStorage.getItem("userId");
          // this.projecTrackAllDetail();
          // this.projectTeam(true);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  activated() {
    if (this.$route.query) {
      this.projectId = JSON.parse(JSON.stringify(this.$route.query.projectId));
      this.queryParams = JSON.parse(JSON.stringify(this.$route.params));
      this.pwfId = JSON.parse(JSON.stringify(this.$route.query.id));
      this.projecTrackAllDetail();
      this.projectTeam(true);

      this.dataAuthInfo = {
        baseInfo: {
          event: "base_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        principalRegInfo: {
          event: "principal_reg_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        documentFile: {
          event: "document_file",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        dingTalkFile: {
          event: "ding_file",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        taskInfo: {
          event: "task_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
      };
    }
  },
  methods: {
    findProjectAllDetail() {
      let that = this;
      that.tableData = null;
      let info = {
        id: that.detail.projectId,
      };
      findProjectAllDetail(info).then((res) => {
        if (res.success) {
          let detail = res.data;
          that.detail.onlineShopList = detail.onlineShopList || null;
          that.$forceUpdate();
        }
      });
    },
    getOnlineShopOne(data) {
      console.log(data);
      this.tableData = null;
      this.onlineShopOneType = data.type;
      this.queryParamsShoProducts.shopId = data.originalId;
      this.getExternalDataProductList(1, this.queryParamsShoProducts.pageSize);
    },
    getExternalDataProductList(num, pageSize) {
      if (this.onlineShopOneType == 1) {
        this.queryParamsShoProducts.providerName = "tb.shop.goods-list";
      } else if (this.onlineShopOneType == 2) {
        this.queryParamsShoProducts.providerName = "jd.shop.goods-list";
      }
      this.queryParamsShoProducts.pageNum = num;
      this.queryParamsShoProducts.pageSize = pageSize;
      // this.queryParamsShoProducts.shopId = this.detail.id;
      if (this.onlineShopOneType == 1) {
        getExternalData(this.queryParamsShoProducts).then((response) => {
          // console.log(this.tableData);
          if (response.data.success) {
            let tableData = response.data.nodes;
            tableData.forEach((item, index) => {
              if (item.img.indexOf("http") || item.img.indexOf("https")) {
                item.img = "https:" + item.img;
              }
              if (item.url.indexOf("http") || item.url.indexOf("https")) {
                item.url = "https:" + item.url;
              }
            });
            this.tableData = tableData;
            this.queryParamsShoProducts.pageNum = response.data.current_page;
            this.queryParamsShoProducts.pageSize = response.data.page_size;
            this.queryParamsShoProducts.total = response.data.total_results;
            this.queryParamsShoProducts.pageCount = response.data.total_page;
          } else {
            this.tableData = null;
          }
        });
      } else if (this.onlineShopOneType == 2) {
        getExternalData(this.queryParamsShoProducts).then((response) => {
          // console.log(this.tableData);
          if (response.success) {
            let tableData = response.data.Paragraph;
            let page = response.data.Summary;
            tableData.forEach((item, index) => {
              if (
                item.Content.imageurl &&
                (item.Content.imageurl.indexOf("http") ||
                  item.Content.imageurl.indexOf("https"))
              ) {
                item.Content.imageurl =
                  "https://img13.360buyimg.com/imgzone/" +
                  item.Content.imageurl;
              }
            });
            this.tableData = tableData;
            this.queryParamsShoProducts.pageNum = Number(page.Page.PageIndex);
            this.queryParamsShoProducts.pageSize = Number(page.Page.PageSize);
            this.queryParamsShoProducts.total = Number(page.ResultCount);
            this.queryParamsShoProducts.pageCount = Number(page.Page.PageCount);
          } else {
            this.tableData = null;
          }
        });
      }
    },
    handleSizeChangeProductList(val) {
      console.log(val);
      this.getExternalDataProductList(1, val);
    },
    handleCurrentChangeProductList(val) {
      console.log(val);
      if (val > this.queryParamsShoProducts.pageCount) {
        val = this.queryParamsShoProducts.pageCount;
      }
      this.getExternalDataProductList(
        val,
        this.queryParamsShoProducts.pageSize
      );
    },
    openCompetitor(data) {
      console.log(data);
      this.queryParamsProductRecommen.id = data.ProductId;
      getExternalData(this.queryParamsProductRecommen).then((response) => {
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          this.productRecommenlist = tableData;
          // this.queryParamsProduct.pageNum = Number(response.data.PageIndex);
          // this.queryParamsProduct.pageSize = Number(response.data.PageSize);
          // this.queryParamsProduct.total = Number(response.data.TotalRecords);
          // this.queryParamsProduct.pageCount = Number(response.data.totalPage);
        } else {
          this.productRecommenlist = null;
        }
      });
    },
    getExternaNewsList(pageNum, pageSize) {
      this.queryParamsNewList.pageNum = pageNum;
      this.queryParamsNewList.pageSize = pageSize;
      getExternalData(this.queryParamsNewList).then((response) => {
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          this.newList = tableData;
          this.queryParamsNewList.pageNum = Number(response.data.PageIndex);
          this.queryParamsNewList.pageSize = Number(response.data.PageSize);
          this.queryParamsNewList.total = Number(response.data.TotalRecords);
          // this.queryParamsNewList.pageCount = Number(response.data.totalPage);
        } else {
          this.newList = null;
        }
      });
    },
    handleSizeChangeNews(val) {
      console.log(val);
      this.getExternaNewsList(1, val);
    },
    handleCurrentChangeNews(val) {
      console.log(val);
      if (val > this.queryParamsNewList.pageCount) {
        val = this.queryParamsNewList.pageCount;
      }
      this.getExternaNewsList(val, this.queryParamsNewList.pageSize);
    },
    getExternalRecruitmentList(pageNum, pageSize) {
      this.queryParamsRecruitment.pageNum = pageNum;
      this.queryParamsRecruitment.pageSize = pageSize;
      getExternalData(this.queryParamsRecruitment).then((response) => {
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          this.recruitmentList = tableData;
          this.queryParamsRecruitment.pageNum = Number(response.data.PageIndex);
          this.queryParamsRecruitment.pageSize = Number(response.data.PageSize);
          this.queryParamsRecruitment.total = Number(
            response.data.TotalRecords
          );
          // this.queryParamsRecruitment.pageCount = Number(response.data.totalPage);
        } else {
          this.recruitmentList = null;
          this.queryParamsRecruitment.total = 0;
        }
      });
    },
    handleSizeChangeRecruitment(val) {
      console.log(val);
      this.getExternalRecruitmentList(1, val);
    },
    handleCurrentChangeRecruitment(val) {
      console.log(val);
      if (val > this.queryParamsRecruitment.pageCount) {
        val = this.queryParamsRecruitment.pageCount;
      }
      this.getExternalRecruitmentList(
        val,
        this.queryParamsRecruitment.pageSize
      );
    },
    getExternalData(pageNum, pageSize) {
      this.queryParamsProduct.pageNum = pageNum;
      this.queryParamsProduct.pageSize = pageSize;
      getExternalData(this.queryParamsProduct).then((response) => {
        // console.log(this.tableData);
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          // tableData.forEach((item, index) => {
          //   if (item.img.indexOf("http") || item.img.indexOf("https")) {
          //     item.img = "https:" + item.img;
          //   }
          //   if (item.url.indexOf("http") || item.url.indexOf("https")) {
          //     item.url = "https:" + item.url;
          //   }
          // });
          this.productList = tableData;
          this.queryParamsProduct.pageNum = Number(response.data.PageIndex);
          this.queryParamsProduct.pageSize = Number(response.data.PageSize);
          this.queryParamsProduct.total = Number(response.data.TotalRecords);
          // this.queryParamsProduct.pageCount = Number(response.data.totalPage);
        } else {
          this.productList = null;
        }
      });
    },
    handleSizeChange(val) {
      console.log(val);
      this.getExternalData(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.queryParamsProduct.pageCount) {
        val = this.queryParamsProduct.pageCount;
      }
      this.getExternalData(val, this.queryParamsProduct.pageSize);
    },
    handleClick(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      this.tabName2 = tab.name;
      console.log(tab.name + "-------2222");
      if (tab.name == "onlineShop") {
        this.findProjectAllDetail();
      } else if (tab.name == "news") {
        this.queryParamsNewList.searchKey = this.detail.principalName;
        this.getExternaNewsList(1, this.queryParamsNewList.pageSize);
      } else if (tab.name == "product") {
        this.queryParamsProduct.searchKey = this.detail.principalName;
        this.getExternalData(1, this.queryParamsProduct.pageSize);
      } else if (tab.name == "recruitment") {
        this.queryParamsRecruitment.searchKey = this.detail.principalName;
        this.getExternalRecruitmentList(
          1,
          this.queryParamsRecruitment.pageSize
        );
      }
      if (tab.name == "baidu") {
        if (this.detail.searchKeywords) {
          let searchKeywords = JSON.parse(
            JSON.stringify(this.detail.searchKeywords)
          );
          searchKeywords = searchKeywords.replace(/,/g, " ");

          window.open(
            "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
              searchKeywords,
            "baidu"
          );
        } else {
          window.open(
            "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
              this.detail.principalName,
            "baidu"
          );
        }
      } else if (tab.name == "qcc") {
        window.open(
          "https://www.qcc.com/web/search?key=" + this.detail.principalName,
          "qcc"
        );
      }
    },
    tabHandleClick(tab, event) {
      let that = this;
      console.log(tab.index);
      let index = tab.index;
      this.tabName = tab.name;
      console.log(tab.name);
      if (tab.name == "documentList") {
        this.documentListShow = true;
      } else if (tab.name == "projectOperation") {
        console.log("in projectOperation");
        if (this.tabName2 == "onlineShop") {
          console.log("in onlineShop");
          this.findProjectAllDetail();
        } else if (this.tabName2 == "news") {
          console.log("in news");
          this.queryParamsNewList.searchKey = this.detail.principalName;
          this.getExternaNewsList(1, this.queryParamsNewList.pageSize);
        } else if (this.tabName2 == "product") {
          this.queryParamsProduct.searchKey = this.detail.principalName;
          this.getExternalData(1, this.queryParamsProduct.pageSize);
        } else if (this.tabName2 == "recruitment") {
          this.queryParamsRecruitment.searchKey = this.detail.principalName;
          this.getExternalRecruitmentList(
            1,
            this.queryParamsRecruitment.pageSize
          );
        }
      }
      // else if (tab.name == "baidu") {
      //   if (this.detail.searchKeywords) {
      //     let searchKeywords = JSON.parse(
      //       JSON.stringify(this.detail.searchKeywords)
      //     );
      //     searchKeywords = searchKeywords.replace(/,/g, " ");
      //     window.open(
      //       "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
      //         searchKeywords,
      //       "baidu"
      //     );
      //   } else {
      //     window.open(
      //       "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
      //         this.detail.principalName,
      //       "baidu"
      //     );
      //   }
      // } else if (tab.name == "qcc") {
      //   window.open(
      //     "https://www.qcc.com/web/search?key=" + this.detail.principalName,
      //     "qcc"
      //   );
      // }
    },
    changeProject(type) {
      if (type == "previous") {
        if (this.detail.navButton.prevId != null) {
          this.pwfId = JSON.parse(JSON.stringify(this.detail.navButton.prevId));
          this.projecTrackAllDetail();
        } else {
          this.$message({ type: "info", message: ",没有上一个!" });
        }
      } else {
        if (this.detail.navButton.nextId != null) {
          this.pwfId = JSON.parse(JSON.stringify(this.detail.navButton.nextId));
          this.projecTrackAllDetail();
        } else {
          this.$message({ type: "info", message: ",没有下一个!" });
        }
      }
    },
    followProject() {
      let that = this;
      let title = that.detail.isFavorite == 1 ? "取消关注" : "关注";
      let info = {
        refType: "project_workflow",
        refId: that.detail.id,
      };
      this.$confirm(
        "确认" + title + "业务流程 [" + that.title + "] 吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (that.detail.isFavorite == "0") {
            followProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "关注成功!" });
                that.projecTrackAllDetail();
              } else {
                that.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          } else if (that.detail.isFavorite == "1") {
            unFollowProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "取消关注成功!" });
                that.projecTrackAllDetail();
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    opeMyMission2(row) {
      console.log(row);
      let status =
        row.status == 1
          ? 4
          : row.status == 2
          ? 5
          : row.status == 3
          ? 6
          : row.status == 4
          ? 6
          : row.status == 5
          ? 5
          : "";
      this.$emit("listenBusinessProcesDetailClose", {
        type: "myMission",
        isClose: true,
        info: {
          taskId: row.id,
          projectId: row.projectId,
          nodeId: row.nodeId,
          workflowId: row.workflowId,
          workflowVersion: this.detail.workflowVersion,
          projectWorkflowId: row.projectWorkflowId,
          projectWorkflowDetailId: row.projectWorkflowDetailId,
          status: status,
        },
      });
    },
    openTaskManagement(row) {
      this.$emit("listenBusinessProcesDetailClose", {
        type: "taskManagement",
        isClose: true,
        info: {
          taskId: row.id,
          projectId: row.projectId,
          projectWorkflowId: row.projectWorkflowId,
          projectWorkflowDetailId: row.projectWorkflowDetailId,
          ownerId: row.ownerId,
          status: row.status, // 0未知,1待启动,2进行中,3已完成,4终止,5暂停
        },
      });
    },
    openTaskCommen(row) {
      console.log(row);
      this.taskId = row.id;
      this.taskCommentDialog = true;
    },
    taskContentClose() {
      this.taskCommentDialog = false;
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      this.statusSelectShow = false;
    },
    statusSelectChange() {
      this.statusSelectShow = !this.statusSelectShow;
    },
    customColorMethod(percentage) {
      if (percentage < 25) {
        return "#F56C6C";
      } else if (percentage < 50) {
        return "#e6a23c";
      } else if (percentage < 75) {
        return "#409EFF";
      } else {
        return "#67c23a";
      }
    },
    collapseChange(array) {
      if (array) {
        this.documentListShow = true;
      }
    },
    displayStatusListChange(value, id) {
      console.log(value);
      let info = {
        id: id,
        status: value,
      };
      taskStatusEdit(info).then((res) => {
        if (res.success) {
          this.projecTrackAllDetail();
          this.statusSelectShow = !this.statusSelectShow;
          this.$message({ type: "success", message: "任务状态编辑成功!" });
        }
      });
    },
    projectTeam(cb) {
      if (cb) {
        projectAssignUserList(this.id).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    chooseInvestor(ownerId, id) {
      var that = this;
      console.log(ownerId);
      console.log(id);
      let info = {
        id: id,
        ownerId: ownerId,
      };
      editTask(info).then((response) => {
        if (response.success) {
          this.projecTrackAllDetail();
          this.$message({ type: "success", message: "修改任务负责人成功!" });
        }
      });
    },
    listenEvaluateRecordTableClose(params) {
      const { type, isClose } = params;
      this.evaluateRecordTableDialog = !isClose;
      if (type == "sure") {
      }
    },
    addTask(row) {
      console.log(row);
      this.relationList = [
        {
          type: 1,
          targetId: row.projectWorkflowDetailId,
        },
        {
          type: 2,
          targetId: row.projectWorkflowId,
        },
        {
          type: 3,
          targetId: row.projectId,
        },
      ];
      this.add1TaskType = "add";
      this.add1TaskDalog = true;
    },
    listenAdd1TaskClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      this.add1TaskDalog = !isClose;
      if (type == "sure") {
        this.projecTrackAllDetail();
      }
    },
    openContent(title, val, type) {
      this.contentDialogTitle = title;
      this.contentDialogType = type;
      console.log(val);
      if (type == "1") {
        this.evaluateRecordIfno = val;
        this.evaluateRecordId = String(val.id);
        this.evaluateRecordTableDialog = true;
        // this.moduleScoreHeaderName =
        //   "V" +
        //   val.workflowVersion +
        //   val.projectWorkflowName +
        //   "_" +
        //   val.workflowNodeName +
        //   "_" +
        //   val.taskName;
        // let info = {};
        // // info.projectId = val.projectId;
        // // // info.projectWorkflowId = val.projectWorkflowId;
        // // info.taskId = val.taskId;
        // // info.templateId = val.templateId;
        // info.id = val.id;
        // this.getEvaluationRecordDetail(info);
      } else {
        this.content = val.content;
        this.commentMeetingId = val.id;
        this.contentDialog = true;
      }
    },
    getEvaluationRecordDetail(info) {
      console.log("data1");
      var that = this;
      that.tableLoading = true;
      that.evaluateRecordTableData = [];

      getEvaluationRecordDetail(info).then((res) => {
        if (res.success) {
          let info = res.data;
          that.evaluateRecordTableData = info.recordItemList;
          that.rowMergeArrs = that.rowMergeHandle(
            that.needMergeArr,
            that.evaluateRecordTableData
          ); // 处理数据
          that.tableLoading = false;
          that.contentDialog = true;
        }
      });
    },

    /**
     * @description 实现合并行或列
     * @param row:Object 需要合并的列name 如:'name' 'id'
     * @param column:Object 当前行的行数，由合并函数传入
     * @param rowIndex:Number 当前列的数据，由合并函数传入
     * @param columnIndex:Number 当前列的数据，由合并函数传入
     *
     * @return 函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，第二个元素代表colspan。 也可以返回一个键名为rowspan和colspan的对象
     * 参考地址：https://element.eleme.cn/#/zh-CN/component/table#table-biao-ge
     */
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(column)
      // 没办法循环判断具体是那一列 所以就只好写了多个if
      if (column.label === "模块")
        return this.mergeAction("moduleName", rowIndex, column);
      if (column.label === "细分模块")
        return this.mergeAction("subModuleName", rowIndex, column);
    },
    /**
     * @description 根据数组来确定单元格是否需要合并
     * @param val:String 需要合并的列name 如:'name' 'id'
     * @param rowIndex:Number 当前行的行数，由合并函数传入
     * @param colData:Object 当前列的数据，由合并函数传入
     *
     * @return 返回值为一个数组表示该单元格是否需要合并; 说明: [0,0]表示改行被合并了 [n+,1]n为1时表示该单元格不动,n大于1时表示合并了N-1个单元格
     */
    mergeAction(val, rowIndex, colData) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },
    /**
     * @description 根据数组将指定对象转化为相应的数组
     * @param arr:Array[String] 必填 必须是字符串形式的数组
     * @param data:Array 必填 需要转化的对象
     *
     * @return 返回一个对象
     * 例：rowMerge(['name','value'], [{value:'1', name:'小明'}, {value:'2', name:'小明'}, {value:'3', name:'小明'}, {value:'1', name:'小明'}, {value:'1', name:'小明'}])
     * 返回值: {
     *          name:{
     *            rowArr: [5, 0, 0, 0, 0]
     *            rowMergeNum: 0,
     *          },
     *          value: {
     *            rowArr: [1, 1, 1, 2, 0],
     *            rowMergeNum: 3
     *          }
     *        }
     */
    rowMergeHandle(arr, data) {
      if (!Array.isArray(arr) && !arr.length) return false;
      if (!Array.isArray(data) && !data.length) return false;
      let needMerge = {};
      arr.forEach((i) => {
        needMerge[i] = {
          rowArr: [],
          rowMergeNum: 0,
        };
        data.forEach((item, index) => {
          if (index === 0) {
            needMerge[i].rowArr.push(1);
            needMerge[i].rowMergeNum = 0;
          } else {
            if (item[i] === data[index - 1][i]) {
              needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1;
              needMerge[i].rowArr.push(0);
            } else {
              needMerge[i].rowArr.push(1);
              needMerge[i].rowMergeNum = index;
            }
          }
        });
      });
      return needMerge;
    },
    evaluationRecord(info) {
      this.tableLoading = true;
      //评估查询
      this.evaluateRecordTableData = [];
      // this.textArr = [];
      evaluationRecord(info).then((res) => {
        if (res.success) {
          // for (var i = 0; i < res.data.evaluationTimes; i++) {
          //   this.textArr.push(i + 1);
          // }
          this.evaluateRecordTableData = res.data.recordItemList;
          // for (let i = 3; i < res.data.evaluationTimes * 2 + 3; i++) {
          //   if (i % 2 == 1) {
          //     this.indexOf.push(i);
          //   }
          // }
          // this.getSpanArr(this.evaluateRecordTableData);
          this.rowMergeArrs = this.rowMergeHandle(
            this.needMergeArr,
            this.evaluateRecordTableData
          ); // 处理数据
          this.$forceUpdate();
        }
      });
    },
    // 2、 因为要合并的行数是不固定的，此函数是实现合并随意行数的功能
    getSpanArr(data) {
      console.log("data");
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].moduleName === data[i - 1].moduleName) {
            // 如果moduleName相等就累加，并且push 0  这里是根据一样的moduleName匹配
            console.log(data[i].moduleName);
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
      this.contentDialog = true;
      this.tableLoading = false;

      this.$forceUpdate();
    },
    // 3、合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      for (let i = 0; i < this.indexOf.length; i++) {
        if (columnIndex === this.indexOf[i]) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },

    getSpanArrTask(data) {
      this.spanArrTask = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArrTask.push(1);
          this.posTask = 0;
        } else {
          if (data[i].nodeName === data[i - 1].nodeName) {
            // 如果nodeName相等就累加，并且push 0  这里是根据一样的nodeName匹配
            this.spanArrTask[this.posTask] += 1;
            this.spanArrTask.push(0);
          } else {
            // 不相等push 1
            this.spanArrTask.push(1);
            this.posTask = i;
          }
        }
      }
    },
    taskObjectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArrTask[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    handleContentClose() {
      this.contentDialog = false;
    },
    dialogClose() {
      this.$emit("listenBusinessProcesDetailClose", {
        type: "cancel",
        isClose: true,
      });
    },
    handleExport(item) {
      // let userId = window.localStorage.getItem("userId");
      // let memberList = JSON.parse(JSON.stringify(this.detail.memberList));
      // let download = memberList.some((value, index) => value.userId == userId);
      // if (download) {
      //   //下载文件
      //   var elemIF = document.createElement("iframe");
      //   elemIF.src = item.url;
      //   elemIF.style.display = "none";
      //   document.body.appendChild(elemIF);
      // } else {
      //   this.$message({ type: "warning", message: "您没有下载权限!" });
      // }
      let fileType = item.url.split(".").pop();
      console.log(fileType);
      this.$router.push({
        name: "officeOnLine",
        params: { file: item, url: item.url, fileType: fileType },
      });
      return false;
      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        console.log(item.url);
        let url = encodeURIComponent(item.url);
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      } else if (fileType == "pdf") {
        // console.log(item.url);
        this.imgUrl = null;
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + item.url;
        window.open(url);
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif"
      ) {
        this.pdfUrl = null;
        this.imgUrl = item.url;
        this.dialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(item.url);
      }
    },
    projecTrackAllDetail() {
      this.loading = true;
      var that = this;
      let info = {
        id: this.pwfId,
        ...that.queryParams,
      };
      projecTrackAllDetail(info).then((res) => {
        if (res.success) {
          // console.log(res.data);
          this.detail = res.data;
          this.detail2 = {
            projectId: res.data.projectId,
            projectWorkflowId: res.data.id,
            projectType: res.data.projectType,
          };
          this.title =
            this.detail.projectName +
            "——" +
            this.detail.name +
            "(版本" +
            this.detail.workflowVersion +
            ")";
          this.loading = false;
          if (that.detail.principalRegInfo) {
            that.principalRegInfoNameLink =
              "https://www.qcc.com/web/search?key=" +
              that.detail.principalRegInfo.name;
            if (that.detail.searchKeywords) {
              let searchKeywords = JSON.parse(
                JSON.stringify(this.detail.searchKeywords)
              );
              searchKeywords = searchKeywords.replace(/,/g, " ");
              that.iframeUrlWeibo =
                "https://s.weibo.com/weibo?q=" + searchKeywords;
              that.tiktokKeyword = searchKeywords;
            } else {
              that.iframeUrlWeibo =
                "https://s.weibo.com/weibo?q=" +
                that.detail.principalRegInfo.name;
              that.tiktokKeyword = that.detail.principalRegInfo.name;
            }
          }
          if (this.detail.taskList && this.detail.taskList.length > 0) {
            this.getSpanArrTask(this.detail.taskList);
          }
          this.dataAuth = res.data.projectAuthResult;
          if (that.activeName == "projectOperation") {
            if (this.tabName2 == "baidu") {
              if (this.detail.searchKeywords) {
                let searchKeywords = JSON.parse(
                  JSON.stringify(this.detail.searchKeywords)
                );
                searchKeywords = searchKeywords.replace(/,/g, " ");

                window.open(
                  "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
                    searchKeywords,
                  "baidu"
                );
              } else {
                window.open(
                  "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
                    this.detail.principalName,
                  "baidu"
                );
              }
            } else if (this.tabName2 == "qcc") {
              window.open(
                "https://www.qcc.com/web/search?key=" +
                  this.detail.principalName,
                "qcc"
              );
            } else if (this.tabName2 == "onlineShop") {
              console.log("in onlineShop");
              this.findProjectAllDetail();
            } else if (this.tabName2 == "news") {
              console.log("in news");
              this.queryParamsNewList.searchKey = this.detail.principalName;
              this.getExternaNewsList(1, this.queryParamsNewList.pageSize);
            } else if (this.tabName2 == "product") {
              this.queryParamsProduct.searchKey = this.detail.principalName;
              this.getExternalData(1, this.queryParamsProduct.pageSize);
            } else if (this.tabName2 == "recruitment") {
              this.queryParamsRecruitment.searchKey = this.detail.principalName;
              this.getExternalRecruitmentList(
                1,
                this.queryParamsRecruitment.pageSize
              );
            }
          }
        }
      });
    },
  },
  created() {},

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.details {
  height: 350px;
  width: 100%;
}
.version {
  color: #606266;
  font-size: 12px;
  margin-left: 5px;
  // align-self: flex-end;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 130px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 130px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      width: calc(100% - 130px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 130px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
    div {
      width: calc(100% - 130px);
      // float: right;
    }
  }
}
.statusSelect {
  cursor: pointer;
}
.meetingLeft {
  align-self: flex-start;
  min-width: 60px;
  text-align: right;
}
.taskButton {
  padding: 5px 5px !important;
}
.title {
  text-align: center;
  margin-bottom: 10px;
}
.name {
  margin-right: 10px;
}
.link {
  margin-left: 10px;
}
.picImg {
  width: 60px;
}
</style>
